<template>
  <div class="content-wrapper">
    <bo-page-header></bo-page-header>
    <div class="content pt-0">
      <b-card
        header-class="header-elements-inline"
        no-body
        v-if="!isAdd"
      >
        <template #header>
          <b-card-title title-tag="h6" class="font-weight-bold">Leads</b-card-title>
          <div class="header-elements">
            <b-form-row>
              <b-col md="auto">
                <b-form-select size="sm" :options="priorities" v-model="selPriority">
                  <option value="">-- All Priority --</option>
                </b-form-select>
              </b-col>
              <b-col md="auto">
                <b-form-select size="sm" :options="sources" v-model="selSource">
                  <option value="">-- All Sources --</option>
                </b-form-select>
              </b-col>
              <b-col md="auto">
                <b-form-select size="sm" :options="statuses" v-model="selStatus">
                  <option value="">-- All Status --</option>
                </b-form-select>
              </b-col>
            </b-form-row>
          </div>
        </template>

        <b-table
          responsive
          :fields="crmLeadsFields"
          :items="crmLeadsDatas"
          bordered
          :per-page="10"
          :current-page="crmLeadsPage"
          table-class="text-nowrap"
        >
          <template #cell(num)="data">
            {{ data.index += 1 + (crmLeadsPage - 1)*5  }}
          </template>
          <template #cell(date)="data">
            {{ data.value | moment('ll') }}
          </template>
          <template #cell(name)="data">
            <strong>{{ data.value }}</strong>
          </template>
          <template #cell(contact)="data">
            <div class="contact_list">
              <ul>
                <li v-for="(v, k) in data.value" :key="k">
                  <i :class="`icon-${k == 'email' ? 'envelop3' : 'phone2'}`"></i>
                  {{ v }}
                </li>
              </ul>
            </div>
          </template>
          <template #cell(statusPriority)="data">
            <span class="badge badge-info text-uppercase">{{ data.value.status }}</span>
            <span class="badge badge-danger text-uppercase ml-1">{{ data.value.priority }}</span>
          </template>
          <template #cell(action)>
            <a href="#" data-toggle="modal" data-target="#modal_fu"
              class="btn bg-transparent border-info-400 text-info-400 rounded-round border-2 btn-icon mr-1">
              <i class="icon-phone-plus2"></i>
            </a>

            <a href="#"
              class="btn bg-transparent border-success-400 text-success-400 rounded-round border-2 btn-icon mr-1">
              <i class="icon-pencil7"></i>
            </a>

            <a href="#"
              class="btn bg-transparent border-warning-400 text-warning-400 rounded-round border-2 btn-icon mr-3">
              <i class="icon-cross2"></i>
            </a>
          </template>
        </b-table>
        <template #footer>
          <b-pagination
            :per-page="10"
            :total-rows="crmLeadsPage.length"
            class="pagination-flat"
            v-model="crmLeadsPage"
            hide-goto-end-buttons
            align="end"
          >
            <template #prev-text>&larr; &nbsp; Prev</template>
            <template #next-text>Next &nbsp; &rarr;</template>
          </b-pagination>
        </template>
      </b-card>
      <template v-else>
        <b-card>
          <template #header>
            <b-card-title title-tag="h6" class="font-weight-bold">Lead Information</b-card-title>
          </template>
          <b-row>
            <b-col md="8">
              <b-form-group
                label-for="leadOwner"
                label-cols-lg="2"
                content-cols-lg="7"
              >
                <template #label>Lead Owner<small class="mandatory_txt">*</small></template>
                <v-select id="leadOwner" />
              </b-form-group>
              <b-form-group
                label-for="leadName"
                label-cols-lg="2"
                content-cols-lg="10"
              >
                <template #label>Name<small class="mandatory_txt">*</small></template>
                <b-form-row>
                  <b-col md="3">
                    <v-select />
                  </b-col>
                  <b-col md="9">
                    <b-form-input placeholder="e.g. Sofyan Abdullah" />
                  </b-col>
                </b-form-row>
              </b-form-group>
              <b-form-group
                label-for="leadPhone"
                label-cols-lg="2"
                content-cols-lg="10"
              >
                <template #label>Mobile Phone<small class="mandatory_txt">*</small></template>
                <b-form-row>
                  <b-col md="3">
                    <b-form-input placeholder="Country Code" />
                  </b-col>
                  <b-col md="9">
                    <b-form-input placeholder="e.g. 9767 877 8885" />
                  </b-col>
                </b-form-row>
              </b-form-group>
              <b-form-group
                label-for="leadTelephone"
                label-cols-lg="2"
                content-cols-lg="10"
              >
                <template #label>Telephone<small class="mandatory_txt">*</small></template>
                <b-form-row>
                  <b-col md="3">
                    <b-form-input placeholder="Country Code" />
                  </b-col>
                  <b-col md="9">
                    <b-form-input placeholder="e.g. 9767 877 8885" />
                  </b-col>
                </b-form-row>
              </b-form-group>
              <b-form-group
                label-for="leadEmail"
                label-cols-lg="2"
                content-cols-lg="7"
              >
                <template #label>Email<small class="mandatory_txt">*</small></template>
                <b-form-input type="email" placeholder="e.g. yourname@gmail.com" />
              </b-form-group>
              <b-form-group
                label-for="leadSource"
                label-cols-lg="2"
                content-cols-lg="7"
              >
                <template #label>Source<small class="mandatory_txt">*</small></template>
                <v-select id="leadSource" />
              </b-form-group>
              <b-form-group
                label-for="leadAddress"
                label-cols-lg="2"
                content-cols-lg="9"
              >
                <template #label>Address<small class="mandatory_txt">*</small></template>
                <b-form-textarea rows="3" id="leadAddress" placeholder="e.g. Jl. Pinus 2 No 13C, Jakarta Selatan" />
              </b-form-group>
              <b-form-group
                label-for="leadStatus"
                label-cols-lg="2"
                content-cols-lg="4"
              >
                <template #label>Lead Status<small class="mandatory_txt">*</small></template>
                <v-select id="leadStatus" />
              </b-form-group>
              <b-form-group
                label-for="leadPriority"
                label-cols-lg="2"
                content-cols-lg="4"
              >
                <template #label>Lead Priority<small class="mandatory_txt">*</small></template>
                <v-select id="leadPriority" />
              </b-form-group>
              <b-form-group>
                <b-button variant="light" v-b-toggle="'leadCollapse'">
                  <i class="icon-plus2 mr-2"></i> Add Additional Data
                </b-button>
              </b-form-group>
              <b-collapse id="leadCollapse">
                <b-form-group
                  label-cols-lg="2"
                  content-cols-lg="7"
                  label-for="leadAnnIncome"
                  label="Annual Income"
                >
                  <b-input-group prepend="Rp">
                    <b-form-input id="leadAnnIncome" placeholder="e.g. 100.000.000" />
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  label-cols-lg="2"
                  content-cols-lg="7"
                  label-for="leadCompany"
                  label="Company"
                >
                  <b-form-input id="leadCompany" placeholder="e.g. Company Name" />
                </b-form-group>
                <b-form-group
                  label-cols-lg="2"
                  content-cols-lg="7"
                  label-for="leadIndustry"
                  label="Industry"
                >
                  <b-form-input id="leadIndustry" placeholder="e.g. Industry" />
                </b-form-group>
                <b-form-group
                  label-cols-lg="2"
                  content-cols-lg="7"
                  label-for="leadWebsite"
                  label="Website"
                >
                  <b-form-input id="leadWebsite" placeholder="e.g. https://yourbusiness.com" />
                </b-form-group>
                <b-form-group
                  label-cols-lg="2"
                  content-cols-lg="6"
                  label-for="leadInstagram"
                  label="Instagram"
                >
                  <div class="form-group-feedback form-group-feedback-left">
                    <b-form-input id="leadInstagram" />
                    <div class="form-control-feedback">
                      <i class="icon-instagram"></i>
                    </div>
                  </div>
                </b-form-group>
                <b-form-group
                  label-cols-lg="2"
                  content-cols-lg="6"
                  label-for="leadfacebook"
                  label="Facebook"
                >
                  <div class="form-group-feedback form-group-feedback-left">
                    <b-form-input id="leadfacebook" />
                    <div class="form-control-feedback">
                      <i class="icon-facebook"></i>
                    </div>
                  </div>
                </b-form-group>
                <b-form-group
                  label-cols-lg="2"
                  content-cols-lg="6"
                  label-for="leadtwitter"
                  label="Twitter"
                >
                  <div class="form-group-feedback form-group-feedback-left">
                    <b-form-input id="leadtwitter" />
                    <div class="form-control-feedback">
                      <i class="icon-twitter"></i>
                    </div>
                  </div>
                </b-form-group>
                <b-form-group
                  label-cols-lg="2"
                  content-cols-lg="6"
                  label-for="leadlinkedin"
                  label="LinkedIn"
                >
                  <div class="form-group-feedback form-group-feedback-left">
                    <b-form-input id="leadlinkedin" />
                    <div class="form-control-feedback">
                      <i class="icon-linkedin"></i>
                    </div>
                  </div>
                </b-form-group>
                <b-form-group
                  label-cols-lg="2"
                  content-cols-lg="6"
                  label-for="leadImage"
                  label="Lead Image"
                >
                  <b-form-file id="leadImage" />
                </b-form-group>
              </b-collapse>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <template #header>
            <b-card-title title-tag="h6" class="font-weight-bold">Description</b-card-title>
          </template>
          <b-row>
            <b-col md="8">
              <b-form-group
                label-cols-lg="2"
                content-cols-lg="8"
                label-for="descNeeds"
              >
                <template #label>Needs <small class="mandatory_txt">*</small></template>
                <b-form-input id="descNeeds" />
              </b-form-group>
              <b-form-group
                label-cols-lg="2"
                content-cols-lg="9"
                label-for="descDescription"
              >
                <template #label>Description <small class="mandatory_txt">*</small></template>
                <b-form-textarea cols="4" id="descDescription" />
              </b-form-group>
              <b-form-group
                label-cols-lg="2"
                content-cols-lg="9"
                label-for="descNotes"
              >
                <template #label>Notes <small class="mandatory_txt">*</small></template>
                <b-form-textarea cols="4" id="descNotes" />
              </b-form-group>
              <b-form-group
                label-cols-lg="2"
                content-cols-lg="6"
                label-for="descAttachment"
                label="Attachment"
              >
                <b-form-file id="descAttachment" />
              </b-form-group>
              <b-form-group
                label-cols-lg="2"
                content-cols-lg="8"
                label-for="descProjectValue"
              >
                <template #label>Project Value <small class="mandatory_txt">*</small></template>
                <b-input-group prepend="Rp">
                  <b-form-input placeholder="e.g. 100.000.000" id="descProjectValue" />
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols-lg="2"
                content-cols-lg="8"
                label-for="descSiteVisit"
              >
                <template #label>Site Visit <small class="mandatory_txt">*</small></template>
                <b-form-radio-group :options="siteVisit" />
              </b-form-group>
              <b-form-group
                label-cols-lg="2"
                content-cols-lg="8"
                label-for="descAppointment"
              >
                <template #label>Appointment <small class="mandatory_txt">*</small></template>
                <b-form-datepicker id="descAppointment" />
              </b-form-group>
            </b-col>
          </b-row>
          <template #footer>
            <b-button variant="transparent" class="bg-indigo-400 mr-1">
              <i class="icon-check2  mr-2"></i> Submit Lead
            </b-button>
            <b-button variant="transparent" class="bg-indigo-400 mr-1"><i class="icon-plus2 mr-2"></i> Save &amp; New</b-button>
            <b-button variant="transparent" class="bg-slate-400">Cancel</b-button>
          </template>
        </b-card>
        <b-card>
          <table class="table striped">
            <tr>
              <td><v-select /></td>
              <td>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Hic fugit commodi dolorum ut quae? Amet nostrum iusto in odio doloremque architecto maxime aliquid facere neque! Consequuntur quis maiores nobis explicabo!</p>
              </td>
              <td>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis eius dicta minus expedita suscipit minima voluptatem autem molestiae? Praesentium reprehenderit voluptatum officiis aut saepe quia fuga tempora nobis iusto et.</p>
              </td>
            </tr>
          </table>
        </b-card>
      </template>
    </div>
    <bo-footer></bo-footer>
  </div>
</template>

<script>
import GlobalVue from '../../libs/Global.vue'
import tableDatas from '@/dummies/tableDatas'

export default {
  extends: GlobalVue,
  data() {
    return {
      selPriority: '',
      selSource: '',
      selStatus: '',
      priorities: ['Hot', 'Medium', 'Cold', 'Default'],
      sources: ['Instagram', 'Ads 1'],
      statuses: ['Open', 'Reject', 'Close'],
      siteVisit: ['Yes', 'No'],
      crmLeadsFields: tableDatas.crmLeadsFields,
      crmLeadsDatas: tableDatas.crmLeadsDatas,
      crmLeadsPage: 1,
    }
  }
}
</script>